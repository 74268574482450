import { isChannelLockedAtom } from "@sunrise/yallo-channel";
import { useAtomValue } from "jotai";

import { Lock } from "@/components/lock/lock";
import type { ChannelId } from "@sunrise/backend-types-core";

export type ChannelLockIndicatorProps = {
  channelId: ChannelId;
  focused?: boolean;
  borderRadius?: number;
} & CommonProps;

export function ChannelLockIndicator({
  channelId,
  "data-testid": dataTestId = "channel-lock-indicator",
  className,
  style,
  focused,
  borderRadius,
}: ChannelLockIndicatorProps): null | JSX.Element {
  const isLocked = useAtomValue(isChannelLockedAtom(channelId));
  if (!isLocked) return null;

  return (
    <Lock
      style={style}
      className={className}
      data-testid={dataTestId}
      focused={focused}
      radius={borderRadius}
    />
  );
}
