import type { ChannelGroupId } from "@sunrise/backend-types-core";
import { cookieStorage } from "@sunrise/store";
import { Nullable } from "@sunrise/utils";
import { atomWithStorage, createJSONStorage } from "jotai/utils";

/**
 * This stores the channel group that is currently set in the state.
 * If there is no channel group selected, it will be null. It may also contain a value that doesn't actually exist.
 * If you want to know the actual channel group that is selected, you need to use the @see selectedChannelGroupAtom.
 *
 * @see selectedChannelGroupIdAtom for the selected channel group. That will automatically select the first one if none is selected.
 */

type ChannelGroupIdAtom = Nullable<ChannelGroupId>;
export const CURRENT_CHANNEL_GROUP_STORAGE_KEY =
  "current-channel-group-id" as const;

export const currentChannelGroupIdAtom = atomWithStorage<ChannelGroupIdAtom>(
  CURRENT_CHANNEL_GROUP_STORAGE_KEY,
  cookieStorage.getItem(CURRENT_CHANNEL_GROUP_STORAGE_KEY) ?? null,
  createJSONStorage<ChannelGroupIdAtom>(() => cookieStorage),
);
currentChannelGroupIdAtom.debugLabel = "currentChannelGroupIdAtom";
