import { assetDetailsByIdAtom } from "@sunrise/asset";
import { currentLanguageAtom, getLocalizedValue } from "@sunrise/i18n";
import { type Nullable } from "@sunrise/utils";
import { useAtomValue } from "jotai";

import { getSeasonEpisodePrefix } from "@/modules/details";

import { useEpgRelativeTime } from "./use-epg-relative-time";
import type {
  ProgramResultItem,
  Recording,
  RecordingAssetType,
} from "@sunrise/backend-types";
import type { AssetId } from "@sunrise/backend-types-core";

type UseProgramDataArgs = {
  asset: Nullable<Recording["asset"] | ProgramResultItem["details"]["asset"]>;
  actual_start: Nullable<string>;
  actual_end: Nullable<string>;
  expiresAt?: Nullable<Recording["expires_at"]>;
  seriesAssetId?: Nullable<AssetId>;
};
type ProgramData = {
  start: Nullable<string>;
  end: Nullable<string>;
  title: Nullable<string>;
  type: Nullable<
    RecordingAssetType | ProgramResultItem["details"]["asset"]["type"]
  >;
  coverImageUrl: Nullable<string>;
  subtitle?: Nullable<string>;
  expiresIn?: Nullable<number>;
  airTime?: Nullable<string>;
  liveProgress?: Nullable<number>;
  seasonAndEpisode?: Nullable<string>;
  isFinished: boolean;
};

/**
 * Used to determine what to show for a specific program box.
 * It does not include actions like play or resume. It just returns data to show to the user.
 *
 * For actually playing out content, use the `usePlayProgram` hook.
 * But both hooks share the same logic to determine if a program can be resumed or not and at what point.
 */
export const useProgramData = ({
  asset,
  actual_start,
  actual_end,
  expiresAt,
  seriesAssetId,
}: UseProgramDataArgs): ProgramData => {
  const language = useAtomValue(currentLanguageAtom);

  const { start, end, airTime, expiresIn, liveProgress, isFinished } =
    useEpgRelativeTime({
      start: actual_start,
      end: actual_end,
      expiresAt,
    });

  const title = asset?.title && getLocalizedValue(asset.title, language);
  const subtitle =
    asset?.subtitle && getLocalizedValue(asset.subtitle, language);
  const type = asset?.type;
  const coverImageUrl = asset?.poster_path;

  const seriesAsset = useAtomValue(assetDetailsByIdAtom(seriesAssetId));
  let seasonAndEpisode: Nullable<string> = null;
  if (
    seriesAssetId &&
    seriesAsset.data?.id === seriesAssetId &&
    asset?.type === "seriesepisode" &&
    seriesAsset.data.type === "series"
  ) {
    const season = seriesAsset.data.seasons.find((s) =>
      s.episodes.some((e) => e.id === asset.id),
    );
    const episode = season?.episodes.find((e) => e.id === asset.id);

    seasonAndEpisode = getSeasonEpisodePrefix(season?.season, episode?.episode);
  }

  return {
    start,
    end,
    airTime,
    expiresIn,
    title,
    subtitle,
    liveProgress,
    type,
    coverImageUrl,
    isFinished,
    seasonAndEpisode,
  };
};
