import {
  availableChannelPackagesAtom,
  channelsOfCurrentSelectedGroupAtom,
} from "@sunrise/yallo-channel";
import { atom } from "jotai";

/**
 * A list of channels we expect to be playable for the enduser.
 * So in effect a list of channels the user should be able to zap over.
 */
export const zappableChannelsAtom = atom(async (get) => {
  const allChannels = await get(channelsOfCurrentSelectedGroupAtom);
  const packages = await get(availableChannelPackagesAtom);
  return allChannels.filter((it) => packages[it.id]);
});
