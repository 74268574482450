import {
  pageAnalyticsRunEffect,
  playerAnalyticsRunEffect,
} from "@sunrise/analytics";
import { selectIsLoggedIn } from "@sunrise/jwt";
import { actionLocationNavigate, locationAtom } from "@sunrise/location";
import { suspendPlayerOnBackgroundEffect } from "@sunrise/player";
import { processVisibilityAtom } from "@sunrise/process-visibility";
import { channelPackagesQueryAtom } from "@sunrise/yallo-channel";
import { useAutorecover } from "@sunrise/yallo-common-player-manager";
import { reactForRecordingsToSocketEffect } from "@sunrise/yallo-recordings";
import { socketLogUnhandledEffect } from "@sunrise/yallo-websocket";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { isNil } from "lodash";
import { ReactElement, Suspense } from "react";
import { createPortal } from "react-dom";
import { Router as Wouter } from "wouter";

import { route } from "@/config/route";
import { Router } from "@/router";

import { SCREEN_HEIGHT_IN_PX } from "./core";
import { useCloseApp } from "./features/routing/use-close-app";
import { shouldBlockPageAnalyticsWhenMenuOpenEffect } from "./modules/analytics/block-page-analytics-when-menu-open.effect";
import { updateGrowthbookAttributesEffect } from "./modules/growthbook/update-growthbook-attributes.effect";
import { useKeyboardNavigation } from "./modules/keyboard-navigation";
import { sentryLogOOMKillsAtom } from "./modules/memory/sentry-log-oom-kills.atom";
import { sentryMemoryMonitoringAtom } from "./modules/memory/sentry-memory-monitoring.atom";
import { isMenuAllowedAtom } from "./modules/menu";
import { setPageContentEffect } from "./modules/pages/set-page-content.effect";
import { autoReloadAtom } from "./modules/process/auto-reload.atom";
import { killOnBackgroundEffect } from "./modules/process/kill-on-background.effect";
import { sentrySyncTokensEffect } from "./modules/sentry/sentry-sync-tokens.effect";
import { TransitionLayer } from "./transition-layer";
import { reactForUserToSocketEffect } from "@sunrise/yallo-user";
import { reactForContinueWatchingToSocketEffect } from "@sunrise/yallo-continue-watching";

const isDevOrLocal =
  import.meta.env.MODE === "development" ||
  import.meta.env.MODE === "localhost";
const NULL_ATOM = atom(null);

export function Root(): ReactElement {
  const isLoggedIn = useAtomValue(selectIsLoggedIn);
  const dispatchLocation = useSetAtom(locationAtom);
  const isMenuAllowed = useAtomValue(isMenuAllowedAtom);

  useCloseApp({ isEnabled: true, useExit: true });

  // We just want to subscribe to the backgrounded state.
  // If we do this here we ensure the state will always be monitored. Even when we are not actively using the background state.
  useAtomValue(processVisibilityAtom);

  // A pre-requisite for analytics to work is that we have a page content.
  useAtomValue(setPageContentEffect);

  // Listen for analytics at the root level. This will spin up a socket, make sure it is authenticated, and push all page/player events on the socket.
  // As a side-effect it'll also connect the socket with all the other modules that need it should it not yet have been done.
  // NOTE: Noticed that if we export a single effect from the package that internally subscribes to these 2 effects, they never run.
  // Their dependency on the socket is not re-evaluated when the socket is set.
  useAtomValue(pageAnalyticsRunEffect);
  useAtomValue(playerAnalyticsRunEffect);
  useAtomValue(shouldBlockPageAnalyticsWhenMenuOpenEffect);

  // We want to run autorecovery on the entire app. Else when we have an error on the TV detail page, we do not retry.
  useAutorecover();

  // Need to listen to this for now for the modules to kick in on the socket.
  useAtomValue(reactForRecordingsToSocketEffect);
  useAtomValue(reactForUserToSocketEffect);
  useAtomValue(reactForContinueWatchingToSocketEffect);

  // We want to "suspend" the player when we go in the background. From any page we are on.
  // Autorecover should unsuspend the player as well.
  useAtomValue(suspendPlayerOnBackgroundEffect);
  // We want to kill the app under certain conditions when it goes in the background.
  useAtomValue(killOnBackgroundEffect);

  // Monitor the memory & potential kill status of the app.
  useAtomValue(sentryMemoryMonitoringAtom);
  useAtomValue(sentryLogOOMKillsAtom);
  useAtomValue(sentrySyncTokensEffect);

  useAtomValue(isDevOrLocal ? socketLogUnhandledEffect : NULL_ATOM);
  useAtomValue(updateGrowthbookAttributesEffect);

  useKeyboardNavigation({
    isEnabled: isMenuAllowed,
    onGuide: () => {
      dispatchLocation(actionLocationNavigate(route.guide.root()));
    },
  });

  // This will automatically "restart" the app when business logic dictates it should.
  useAtomValue(autoReloadAtom);

  const transitionLayerNode = document.getElementById("transition-layer");
  if (isNil(transitionLayerNode)) {
    throw new Error("Transition layer element does not exist");
  }

  return (
    <div style={{ height: SCREEN_HEIGHT_IN_PX }}>
      {isLoggedIn && (
        <Suspense>
          <PrefetchAfterLogIn />
        </Suspense>
      )}
      <Wouter>
        {createPortal(<TransitionLayer />, transitionLayerNode)}
        <Router />
      </Wouter>
    </div>
  );
}

function PrefetchAfterLogIn(): null {
  useAtomValue(channelPackagesQueryAtom);

  return null;
}
