import { selectIsDialogOpen } from "@sunrise/dialogs";
import { isAxiosError } from "axios";
import { useAtomValue } from "jotai";
import { isNil } from "lodash";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import gradient from "static/gradients/details-page.webp";

import { PageSpinner, Spinner } from "@/components";
import { routeParam } from "@/config/route";
import { DetailsInfo } from "@/features/details/details-info/details-info";
import { DetailsTagsRecording } from "@/features/details/details-tags/details-tags-recording";
import { useMenu } from "@/features/menu/use-menu";
import { ErrorBoundary as SentryErrorBoundary } from "@/features/monitoring/error-boundary";
import { useRoutes } from "@/features/routing/use-routes";
import { detailsAtom } from "@/modules/details";
import { epgEntryByIdAtom } from "@/modules/details/epg-entry-by-id.atom";
import { useKeyboardNavigation } from "@/modules/keyboard-navigation";

import { ButtonListError } from "./button-list-error";
import { DetailsButtonList } from "./details-button-list";
import * as styles from "./details.css";
import { MissingDetails } from "./missing-details";

type DetailsPageProps = {
  params: typeof routeParam.details;
};

export function Details(props: DetailsPageProps): JSX.Element {
  // It's forbidden to show menu on this page
  useMenu({ hidden: true });

  // TODO: This could be moved to a general hook and called whenever
  // element is focused to prefetch data
  // onEnterPress -> change id therefore start prefetching,
  // onEnterRelease -> dispatch navigation action (could be done with timeout)
  // Problem is that nothing is actually listening on the derived atoms to display the details. And so it won't actually preload anything.
  const isDialogOpen = useAtomValue(selectIsDialogOpen);

  // NOTE: ensure that back works while data is loading, but not when confirmation dialog is open
  // TODO: find better way to still enable Back while component is loading, but not focus is not on the component
  const routes = useRoutes();
  useKeyboardNavigation({
    onBack: routes.back,
    isEnabled: !isDialogOpen,
  });

  return (
    <ErrorBoundary
      fallback={<MissingDetails />}
      onError={(error) => {
        // only render the missing details fallback if the error is a 404 response, otherwise rethrow the error
        if (isAxiosError(error) && error.response?.status === 404) {
          return;
        }

        throw error;
      }}
    >
      <Suspense fallback={<PageSpinner data-testid="details" />}>
        <SuspendedContent params={props.params} />
      </Suspense>
    </ErrorBoundary>
  );
}

const TEST_ID = "details-page";

type SuspendedContentProps = {
  params: typeof routeParam.details;
};

function SuspendedContent({ params }: SuspendedContentProps): JSX.Element {
  const details = useAtomValue(detailsAtom(params));
  if (isNil(details)) throw new Error("EPG entry is not found");

  const {
    title,
    channelId,
    subtitle,
    channelLogo,
    tags,
    description,
    time,
    backgroundImage,
  } = details;

  if (isNil(channelId)) throw new Error("Channel ID is not found");

  const epgResult = useAtomValue(epgEntryByIdAtom(params.epgId));
  const epg = epgResult.data;

  if (!epg) throw new Error("EPG ID is not found");

  return (
    <div className={styles.mainWrapper}>
      <main
        tabIndex={0}
        data-testid={TEST_ID}
        className={styles.main}
        style={{
          backgroundImage: `url(${gradient}), url(${backgroundImage})`,
          backgroundRepeat: `repeat, no-repeat`,
          backgroundSize: "contain, cover",
          backgroundPositionX: `left, center`,
        }}
      >
        <div className={styles.wrapper}>
          <div className={styles.tagsWrapper}>
            <Suspense>
              <DetailsTagsRecording
                assetId={params.assetId}
                epgId={epg.id}
                tags={tags}
                data-testid={`${TEST_ID}.details-tags`}
              />
            </Suspense>
          </div>
          <DetailsInfo
            title={title}
            subtitle={subtitle}
            description={description}
            channelLogo={channelLogo?.url}
            time={time}
            assetId={params.assetId}
            showCastDirector={true}
            data-testid={`${TEST_ID}.details-info`}
          />

          <div className={styles.buttonList}>
            <SentryErrorBoundary
              fallback={({ eventId }) => <ButtonListError eventId={eventId} />}
            >
              <Suspense
                fallback={
                  <div className={styles.actions}>
                    <Spinner />
                  </div>
                }
              >
                <DetailsButtonList
                  channelId={channelId}
                  epg={epg}
                  time={time}
                  data-testid={`${TEST_ID}.details-button-list`}
                />
              </Suspense>
            </SentryErrorBoundary>
          </div>
        </div>
      </main>
    </div>
  );
}
