import { hostsAtom, httpClientAtom } from "@sunrise/http-client";
import { type Nullable } from "@sunrise/utils";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { atomFamily } from "jotai/utils";
import { isNil } from "lodash";

import { fetchAssetStaff } from "./asset.service";
import { AssetId } from "@sunrise/backend-types-core";
import { AssetStaff, queryKeys } from "@sunrise/backend-types";

export const assetStaffByIdAtom = atomFamily((assetId: Nullable<AssetId>) =>
  atomWithSuspenseQuery<Nullable<AssetStaff>>((get) => {
    const host = get(hostsAtom).api;
    if (isNil(host)) throw new Error("Host is not set");

    const privateApi = get(httpClientAtom).privateApi;
    if (isNil(privateApi)) throw new Error("Private API is not set");

    return {
      enabled: !isNil(assetId),
      queryKey: queryKeys.assetStaffById(assetId),
      queryFn: async () => {
        if (!assetId) return null;

        return fetchAssetStaff(privateApi, host, assetId);
      },
      staleTime: Infinity,
      cacheTime: Infinity,
      retry: false,
    };
  }),
);
