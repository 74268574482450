import { currentLanguageAtom } from "@sunrise/i18n";
import { type Store } from "@sunrise/store";
import { dateToTimeDay } from "@sunrise/time";
import { channelsOfCurrentSelectedGroupAtom } from "@sunrise/yallo-channel";
import { selectEPGEntriesPerDay } from "@sunrise/yallo-epg";
import { type GuideChannel } from "@sunrise/yallo-guide";
import { mockDataAtom } from "@sunrise/yallo-settings";
import { addDays, startOfDay, subDays } from "date-fns";
import { useAtomValue, useStore } from "jotai";
import { atomWithDefault } from "jotai/utils";
import { isEqual, uniqBy } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  GUIDE_WINDOW_DAYS_IN_FUTURE,
  GUIDE_WINDOW_DAYS_IN_PAST,
} from "@/core/constants";

import { generateDummyData } from "../mocks/generate-dummy-data";
import { epgEntryToGuideProgram } from "../utils/epg-entry-to-guide-program";
import type { ChannelId, Language } from "@sunrise/backend-types-core";
import type { ChannelListItem, EPGEntry } from "@sunrise/backend-types";

export type UseGuideDataOnVisibleDataChangedCallback = (
  channelIds: ChannelId[],
  startTime: Date,
  endTime: Date,
) => void;

type VisibleData = {
  /**
   * When channelIds is a number we need to load the first X channels. Else, just load the channelIds passed in.
   */
  channelIds: ChannelId[] | number;
  startTime: Date;
  endTime: Date;
};

/**
 * Hook that is responsible for loading in the correct data and returning it.
 * It will always return all the channels when we have it.
 * The GuidePrograms may not necessarily be on every channel if the data is not visible.
 *
 * @returns
 */
export function useGuideData({
  daysInPast = GUIDE_WINDOW_DAYS_IN_PAST,
  daysInFuture = GUIDE_WINDOW_DAYS_IN_FUTURE,
  enabled = true,
  initialVisibleData,
}: {
  daysInPast: number;
  daysInFuture: number;
  enabled?: boolean;
  initialVisibleData?: VisibleData;
}): {
  data: GuideChannel[];
  endTime: Date;
  onVisibleDataChanged: UseGuideDataOnVisibleDataChangedCallback;
  startTime: Date;
  initialLoading: boolean;
} {
  const startTime = useMemo(() => {
    return startOfDay(subDays(new Date(), daysInPast));
  }, [daysInPast]);

  const endTime = useMemo(() => {
    return startOfDay(addDays(new Date(), daysInFuture + 1));
  }, [daysInFuture]);

  const shouldUseMockData = useAtomValue(mockDataAtom);
  const [visibleData, setVisibleData] = useState<VisibleData | undefined>(
    initialVisibleData,
  );

  const mockData = useGuideMockData({
    startTime,
    enabled: enabled && shouldUseMockData,
    endTime,
  });

  const backendData = useGuideBackendData({
    enabled: enabled && !shouldUseMockData,
    visible: visibleData,
  });

  return {
    startTime,
    endTime,
    initialLoading: shouldUseMockData
      ? mockData.initialLoad
      : backendData.initialLoad,
    data: enabled ? (shouldUseMockData ? mockData.data : backendData.data) : [],
    /**
     * Call this to notify the hook that you are interested in this data.
     * The hook will then attempt to provide said data.
     */
    onVisibleDataChanged: useCallback(
      (newChannelIds, newStartTime, newEndTime) => {
        const next = {
          channelIds: newChannelIds,
          startTime: newStartTime,
          endTime: newEndTime,
        };

        setVisibleData((prev) => {
          // Needed to prevent looping.
          if (isEqual(prev, next)) {
            return prev;
          }

          return next;
        });
      },
      [],
    ),
  };
}

/**
 * For the mocked data we need a stable start and end time. Because we want to generate the data once. Can't have it re-calculate all the time.
 * For the mocked data we are also not working with the VisibleData.
 *
 */
function useGuideMockData({
  startTime,
  endTime,
  enabled,
}: {
  startTime: Date;
  endTime: Date;
  enabled: boolean;
}): { data: GuideChannel[]; initialLoad: boolean } {
  const mockData = useMemo(() => {
    if (!enabled) {
      return [];
    }

    return generateDummyData({
      channelCount: 300,
      startTime,
      endTime,
      getChannelConfigForIndex: (index) => {
        if (index === 0) {
          return {
            exactDuration: 30,
            name: "half hour channel " + index,
          };
        }

        if (index === 1) {
          return {
            exactDuration: 45,
            name: "45m channel " + index,
          };
        }

        if (index % 3 === 0) {
          return {
            minimumDurationInMinutes: 60,
            averageDurationInMinutes: 120,
            name: "longer " + index,
          };
        }

        return undefined;
      },
    });
  }, [startTime, endTime, enabled]);

  return {
    data: mockData,
    initialLoad: false,
  };
}

// NOTE: Doing this so we do not call to ask for the channels when the hook is not enabled.
const emptyChannelsAtom = atomWithDefault(() => []);

function useGuideBackendData({
  enabled,
  visible,
}: {
  enabled: boolean;
  visible?: VisibleData;
  requiredTimes?: Date[];
}): { data: GuideChannel[]; initialLoad: boolean } {
  const [data, setData] = useState<GuideChannel[]>([]);

  const store = useStore();

  const channels = useAtomValue(
    enabled ? channelsOfCurrentSelectedGroupAtom : emptyChannelsAtom,
  );
  const language = useAtomValue(currentLanguageAtom);
  const [initialLoad, setInitialLoad] = useState(true);

  const emptyChannels: Readonly<GuideChannel>[] = useMemo(() => {
    if (!enabled) {
      return [];
    }

    return channels.map((channel) => channelToEmptyGuideChannel(channel));
  }, [channels, enabled]);

  const reqCount = useRef(0);

  useEffect((): void => {
    if (!enabled) {
      return;
    }

    const { startTime, endTime, channelIds } = visible ?? {
      channelIds: channels.map((channel) => channel.id),
    };

    // When we have no time indication yet, we should just return the channels with no events yet.
    // Then the grid can figure out what to timings to show.
    if (!startTime || !endTime) {
      setData(emptyChannels);
      return;
    }

    reqCount.current += 1;
    const reqNr = reqCount.current;

    const needsChannel = (channelId: ChannelId, index: number): boolean =>
      needsChannelData(channelIds, index, channelId);

    const promise = mapTimeframeResponses(
      emptyChannels,
      needsChannel,
      startTime,
      endTime,
      language,
      store,
    );

    promise
      .then((channelData): void => {
        // NOTE: If it takes longer to load the data we may end up showing old data.
        //       Ideally we do work with a query of some sorts here where we can only set the data that matches the most recent request.
        //       Since we mock the API with MSW we can't control the timings. So we can't really test this.
        if (reqCount.current === reqNr) {
          setData(channelData);
        }
      })
      .catch((): void => {
        /* we are ignoring any load issues here.
           It's actually not possible for this promise to reject.
           Since rejections are already resolved to empty arrays here. (see above) */
      })
      .finally(() => {
        setInitialLoad(false);
      });
  }, [enabled, channels, visible, language, emptyChannels, store]);

  return {
    data: data.length === 0 ? emptyChannels : data,
    initialLoad,
  };
}

function mapTimeframeResponses(
  channels: GuideChannel[],
  needsChannel: (channelId: ChannelId, index: number) => boolean,
  startTime: Date,
  endTime: Date,
  language: Language,
  store: Store,
): Promise<GuideChannel[]> {
  const promisedChannelData: Promise<GuideChannel>[] = channels.map(
    async (channel, i) => {
      const channelNeedsData = needsChannel(channel.id, i);

      if (!channelNeedsData) {
        return channel;
      }

      let current = startTime;
      const epgPromises: Promise<EPGEntry[]>[] = [];
      do {
        // NOTE: if we format to local timezone, this could be -/+1 day difference to the actual day we should load from the backend
        const day = dateToTimeDay(current, true);

        // Build or fetch the atom for this day + channel.
        const atom = selectEPGEntriesPerDay({ channelId: channel.id, day });

        // Grab all the EpgEntries for this day. If it fails, so be it. Resolve to an empty array.
        epgPromises.push(store.get(atom).catch(() => []));

        current = addDays(current, 1);
      } while (current < endTime);

      return {
        ...channel,
        // NOTE: days can have overlapping epg entries, so all promises combined would contain some items twice.
        // This causes the guide to render the same item multiple times and thus breaking the navigation
        items: uniqBy(
          (await Promise.all(epgPromises)).flatMap((entries) =>
            entries.map((entry) => epgEntryToGuideProgram(entry, language)),
          ),
          (program) => program.id,
        ),
      };
    },
  );

  return Promise.all(promisedChannelData);
}

function needsChannelData(
  channelIds: VisibleData["channelIds"],
  index: number,
  channelId: ChannelId,
): boolean {
  return typeof channelIds === "number"
    ? index <= channelIds
    : channelIds.includes(channelId);
}

function channelToEmptyGuideChannel(
  channel: ChannelListItem,
): Readonly<GuideChannel> {
  return {
    id: channel.id,
    name: channel.channelName,
    logo: channel.channelLogo,
    channelNumber: channel.channelNumber,
    items: [],
  };
}
