import { channelByIdAtom, selectChannelLogo } from "@sunrise/yallo-channel";
import {
  continueWatchingDataByEpgIdAtom,
  getContinueWatchingProgress,
} from "@sunrise/yallo-continue-watching";
import { epgDetailsForEpgEntryId } from "@sunrise/yallo-epg";
import {
  recordingByEpgIdAtom,
  recordingStatusByEpgIdAtom,
} from "@sunrise/yallo-recordings";
import { atom, useAtomValue } from "jotai";
import { selectAtom, unwrap } from "jotai/utils";
import { type ReactElement, forwardRef, memo, useMemo } from "react";

import { ProgramBox } from "@/components/boxes";
import { programBoxSize } from "@/config/size";
import { useProgramData } from "@/modules/epg/hooks/use-program-data";
import { makePosterImgUrl } from "@/utils/image";

import { ChannelLockIndicator } from "../../features/channel/channel-lock-indicator";
import * as styles from "./list-epg-item.css";
import type { ProgramResult, ProgramResultItem } from "@sunrise/backend-types";

export type ListEpgItemProps = CommonProps & {
  item: ProgramResult["items"][number];
  kind: "normal" | "large" | "channel";
  active?: boolean;
  focused: boolean;
  "data-position"?: number;
};

const NO_DATA_ATOM = atom(null);

/**
 * Regular program card used in the Recommendations section for example.
 */
export const ListEpgItem = forwardRef<HTMLDivElement, ListEpgItemProps>(
  function ListEpgItem(
    {
      "data-testid": dataTestId = "ColumnItemProgram",
      "data-position": dataPosition,
      focused,
      ...props
    },
    ref,
  ): ReactElement {
    if (props.kind === "channel") {
      throw new Error(
        "ColumnItemProgram should not be used with 'channel' kind",
      );
    }

    const cwItem = props.item.type !== "epg" ? props.item : null;
    const epgItem = useAtomValue(
      cwItem
        ? epgDetailsForEpgEntryId({ epgId: cwItem.epg_entry.id })
        : NO_DATA_ATOM,
    );

    const itemDetails = epgItem
      ? epgItem
      : (props.item as ProgramResultItem).details;

    const {
      start,
      end,
      airTime,
      title,
      subtitle,
      liveProgress,
      coverImageUrl,
    } = useProgramData({
      asset: itemDetails.asset,
      actual_start: itemDetails.actual_start,
      actual_end: itemDetails.actual_end,
    });

    const posterUrl = useMemo(
      () =>
        makePosterImgUrl(
          coverImageUrl,
          programBoxSize[props.kind].image.width,
          programBoxSize[props.kind].image.height,
        ),
      [coverImageUrl, props.kind],
    );

    const channelId = itemDetails.channel.id;

    const logoUrl = useAtomValue(
      selectAtom(unwrap(channelByIdAtom(channelId)), selectChannelLogo),
    );

    /**
     * Loads in the recording. But it will only happen when there's actually a recording status for this program.
     */
    const recording = useAtomValue(recordingByEpgIdAtom(itemDetails.id));
    const cwData = useAtomValue(
      continueWatchingDataByEpgIdAtom(itemDetails.id),
    );

    const continueWatchingProgress = useMemo(() => {
      return getContinueWatchingProgress({
        continueWatchingItem: cwData?.continueWatchingItem,
        isFullyWatched: cwData?.isFullyWatched ?? false,
        epgEndTime: new Date(itemDetails.actual_end),
        epgStartTime: new Date(itemDetails.actual_start),
        paddingTimesInMinutes: recording
          ? {
              end: recording.data?.padding_end_minutes ?? 0,
              start: recording.data?.padding_start_minutes ?? 0,
            }
          : null,
      });
    }, [cwData, itemDetails, recording]);

    const recordingStatus = useAtomValue(
      recordingStatusByEpgIdAtom(itemDetails.id),
    );

    return (
      <ProgramBox
        ref={ref}
        data-testid={dataTestId}
        data-focused={focused}
        data-position={dataPosition}
        focused={focused}
        kind={props.kind}
        subtitle={subtitle}
        title={title}
        coverImageUrl={posterUrl}
        start={start}
        startDate={
          itemDetails.actual_start ? new Date(itemDetails.actual_start) : null
        }
        endDate={
          itemDetails.actual_end ? new Date(itemDetails.actual_end) : null
        }
        end={end}
        airTime={airTime}
        liveProgress={liveProgress}
        replayProgress={continueWatchingProgress}
        logoUrl={logoUrl}
        active={props.active}
        recordingState={recordingStatus?.status}
      >
        {!recordingStatus ? (
          <ChannelLockIndicator
            channelId={channelId}
            className={styles.lockIndicator}
            data-testid={`${dataTestId}-${channelId}.channel-lock-indicator`}
            borderRadius={12}
          />
        ) : null}
      </ProgramBox>
    );
  },
);
