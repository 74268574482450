import { type Nullable, isDefined } from "@sunrise/utils";

import { getPrefixAndSubtitle } from "@/modules/details/get-prefix-and-subtitle";

import { MAX_GENRE_TAGS_NUMBER } from "../constants";
import { type DetailsAtomResponse } from "../details.types";
import type { Asset, Channel, EPGEntry } from "@sunrise/backend-types";
import type { Language } from "@sunrise/backend-types-core";
import { getLocalizedValue } from "@sunrise/i18n";
import { getChannelLogo } from "@sunrise/yallo-channel";

export function getAssetTags(asset: Asset, language: Language): string[] {
  return [
    ...(asset.genre
      ?.slice(0, MAX_GENRE_TAGS_NUMBER)
      .map((g) => (g.name ? getLocalizedValue(g.name, language) : null))
      .filter(isDefined) ?? []),
    asset.production_year.toString(),
  ];
}

export function getDetailsForEpgAndAsset(
  epg: Nullable<EPGEntry>,
  asset: Nullable<Asset>,
  channel: Nullable<Channel>,
  language: Language,
  now: Date,
): DetailsAtomResponse | null {
  if (!epg) {
    return null;
  }

  const genreLabels =
    asset?.genre
      ?.slice(0, MAX_GENRE_TAGS_NUMBER)
      .map((g) => (g.name ? getLocalizedValue(g.name, language) : null))
      .filter(isDefined) ?? [];

  const yearLabel = epg.asset.production_year
    ? epg.asset.production_year.toString()
    : null;

  const ageLabel = epg.asset.age_recommendation
    ? `PG-${epg.asset.age_recommendation}`
    : "";

  const start = new Date(epg.actual_start);
  const end = new Date(epg.actual_end);

  const prefixAndSubtitle = getPrefixAndSubtitle(
    epg.asset,
    asset?.subtitle ?? epg.asset.subtitle,
    asset?.original_subtitle,
    language,
  );

  // TODO: Would be nice if we could suppress the description if it's the same as the title.
  return {
    channelLogo: channel ? getChannelLogo(channel) : null,
    channelId: epg.channel.id,
    backgroundImage:
      (epg.asset.poster_url &&
        getLocalizedValue(epg.asset.poster_url, language)) ??
      epg.asset.poster_path,
    title:
      getLocalizedValue(epg.asset.title, language) ??
      asset?.original_title ??
      epg.asset.original_title,
    subtitle: prefixAndSubtitle ? prefixAndSubtitle : null,
    tags: [yearLabel, ageLabel, ...genreLabels]
      .filter(isDefined)
      .filter((v) => v !== ""),
    description: asset?.plot ? getLocalizedValue(asset.plot, language) : null,
    time: { start, end, now },
  };
}
