import { type Nullable } from "@sunrise/utils";
import { atom } from "jotai";
import { selectAtom, unwrap } from "jotai/utils";

import { channelGroupsAtom } from "./channel-groups.atom";
import { currentChannelGroupIdAtom } from "./current-channel-group-id.atom";
import { userChannelGroupsAtom } from "./user-channel-groups.atom";
import type { ChannelGroup } from "@sunrise/backend-types";

/**
 * Use this to determine the current channel group.
 * If you want to set a different channel group, please use @see currentChannelGroupIdAtom.
 *
 * It contains a fallback to the select the first channel group if none is selected. It will select first from the static channel groups.
 * If none are present it would fall back to the user channel groups.
 */
export const selectedChannelGroupAtom = atom<Promise<Nullable<ChannelGroup>>>(
  async (get) => {
    const current = get(currentChannelGroupIdAtom);

    const [channelGroups, userChannelGroups] = await Promise.all([
      get(channelGroupsAtom),
      get(userChannelGroupsAtom),
    ]);

    return (
      channelGroups.data?.find((group) => group.id === current) ??
      userChannelGroups.data?.find((group) => group.id === current) ??
      channelGroups.data?.[0] ??
      userChannelGroups.data?.[0] ??
      null
    );
  },
);
selectedChannelGroupAtom.debugLabel = "selectedChannelGroupAtom";

export const selectedChannelGroupIdAtom = selectAtom(
  unwrap(selectedChannelGroupAtom),
  (group) => group?.id,
  (a, b) => a === b,
);
