import { currentLanguageAtom } from "@sunrise/i18n";
import { selectedChannelGroupAtom } from "@sunrise/yallo-channel-group";
import { atom } from "jotai";

import { allChannelsAtom } from "./all-channels.atom";
import { mapChannelToChannelItem } from "./map-channel-to-channel-item";
import type { ChannelDict, ChannelListItem } from "@sunrise/backend-types";

/**
 * This atom represents the user's channels in their selected channel group.
 * This list should be shown on the TV page and the Guide page.
 *
 * It's pre-sorted on the backend's order attribute.
 */
export const channelsOfCurrentSelectedGroupAtom = atom(async (get) => {
  const [all, group] = await Promise.all([
    get(allChannelsAtom),
    get(selectedChannelGroupAtom),
  ]);

  if (!group) {
    return [];
  }

  const language = get(currentLanguageAtom);
  const orderedChannels = group.channels.sort((a, b) => a.order - b.order);

  const allDict = all.data?.result.reduce<ChannelDict>((acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {});

  return orderedChannels.reduce<ChannelListItem[]>((acc, item) => {
    const channel = allDict[item.channel.id];
    if (!channel) return acc;

    acc.push(mapChannelToChannelItem(channel, item.order, language));
    return acc;
  }, []);
});
channelsOfCurrentSelectedGroupAtom.debugLabel =
  "channelsOfCurrentSelectedGroupAtom";
