import { currentLanguageAtom } from "@sunrise/i18n";
import { getLiveProgress, nowAtom } from "@sunrise/time";
import { useTranslator } from "@sunrise/translator";
import { type Nullable } from "@sunrise/utils";
import { makeRelativeDayOfWeekTranslation } from "@sunrise/yallo-epg";
import { format } from "date-fns";
import { useAtomValue } from "jotai";
import { useEffect, useMemo, useState } from "react";

import { ONE_DAY_IN_MS } from "@/core/constants";

type EpgRelativeTimeArgs = {
  start: Nullable<string>;
  end: Nullable<string>;
  expiresAt: Nullable<string>;
};
type EpgRelativeTime = {
  start: Nullable<string>;
  end: Nullable<string>;
  airTime: Nullable<string>;
  liveProgress?: Nullable<number>;
  expiresIn?: Nullable<number>;
  isFinished: boolean;
};

export const useEpgRelativeTime = ({
  start,
  end,
  expiresAt,
}: EpgRelativeTimeArgs): EpgRelativeTime => {
  const [relativeDay, setRelativeDay] = useState("");

  const language = useAtomValue(currentLanguageAtom);
  const t = useTranslator();
  const now = useAtomValue(nowAtom);

  const startDate = useMemo(() => start && new Date(start), [start]);
  const endDate = useMemo(() => end && new Date(end), [end]);
  const expiresInDate = useMemo(
    () => expiresAt && new Date(expiresAt),
    [expiresAt],
  );

  useEffect(() => {
    if (!startDate || !endDate) return;

    const doAsync = async (): Promise<void> => {
      setRelativeDay(
        await makeRelativeDayOfWeekTranslation(
          startDate,
          endDate,
          now,
          language,
          t,
        ),
      );
    };

    void doAsync();
  }, [end, endDate, language, now, start, startDate, t]);

  if (!startDate || !endDate)
    return {
      start: null,
      end: null,
      airTime: null,
      liveProgress: null,
      expiresIn: null,
      isFinished: false,
    };

  const expiresIn = expiresInDate
    ? Math.round(
        Math.abs((expiresInDate.getTime() - now.getTime()) / ONE_DAY_IN_MS),
      )
    : null;

  const liveProgress = getLiveProgress(startDate, endDate, now);
  const startStr = format(startDate, "HH:mm");
  const endStr = format(endDate, "HH:mm");
  const airTime = relativeDay;
  const isFinished = now >= endDate;

  return {
    start: startStr,
    end: endStr,
    airTime,
    expiresIn,
    liveProgress,
    isFinished,
  };
};
