import { getLocalizedValue } from "@sunrise/i18n";

import { getChannelLogo, getStreamUrl } from "./channel.utils";
import type {
  Channel,
  ChannelListItem,
  ChannelLogo,
} from "@sunrise/backend-types";
import type { Language } from "@sunrise/backend-types-core";

export function mapChannelToChannelItem(
  channel: Channel,
  order: number,
  language: Language,
): ChannelListItem {
  return {
    id: channel.id,
    stream: getStreamUrl(channel),
    channelLogo: getChannelLogo(channel) ?? ({} as ChannelLogo),
    channelNumber: order,
    channelName:
      getLocalizedValue(channel.long_name, language) ?? channel.short_name,
  };
}
