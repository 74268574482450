import { atom } from "jotai";
import { HttpClient } from "./http-client";
import { ngHttpClientConfigAtom } from "./ng-http-client-config.atom";
import {
  manualRetryAxiosInterceptor,
  RefreshTokenOnErrorInterceptor,
} from "@sunrise/backend-core";

export const ngHttpClientAtom = atom((get) => {
  const config = get(ngHttpClientConfigAtom);
  if (!config) {
    throw new Error("ngBaseUrlAtom is not initialized");
  }

  const {
    baseUrl: baseURL,
    doRefreshTokens,
    getAccessToken,
    getRefreshToken,
    resetTokens,
    setTokens,
    isNotKnownUserError,
    onRetry,
    awaitRetry,
    isRetryableError,
    getRetryDelayInSeconds,
  } = config;

  const client = new HttpClient({
    baseURL,
    headers: {
      "Content-Type": "application/json",
      "X-Tenant": "yallo",
    },
  });

  // NOTE: We are not storing anything in a cache here.
  //       That means when we have a secondary ngHttpClient instance we will not be syncing the parallel refreshing of the tokens.
  // TODO: We could fix it by extracting the cache from the RefreshTokenOnErrorInterceptor and passing it as a parameter. Future stuff.
  new RefreshTokenOnErrorInterceptor(
    client.instance,
    getAccessToken,
    getRefreshToken,
    setTokens,
    // TODO: Try to create a promise cache over the passed in function to automatically handle the promise caching.
    //       If we accidentally create a second ngHttpClient instance, it should use the same cache for refreshes.
    //       At least when the baseUrl is the same URL.
    doRefreshTokens,
    resetTokens,
    isNotKnownUserError,
    onRetry,
    true,
  );

  if (awaitRetry && isRetryableError) {
    manualRetryAxiosInterceptor(
      client.instance,
      awaitRetry,
      isRetryableError,
      getRetryDelayInSeconds,
    );
  }

  return client;
});
