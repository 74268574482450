import {
  FocusContext,
  getCurrentFocusKey,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import { useTranslator } from "@sunrise/translator";
import {
  usePlayChannel,
  usePlayProgram,
} from "@sunrise/yallo-common-player-manager";
import { programIsPlayingAtTime } from "@sunrise/yallo-epg";
import { useRecordProgram } from "@sunrise/yallo-recordings";
import { useAtomValue } from "jotai";
import { type ReactNode, useCallback, useEffect } from "react";

import { ICON_SIZE_24 } from "@/components/icon/icon.config";
import { globalFocusKey } from "@/config/focus-key";
import { DetailsButton } from "@/features/details/details-button";
import {
  OutOfReplayWindow,
  shouldShowOutOfReplayMessage,
} from "@/features/details/out-of-replay-window";
import type { TimeInfo } from "@/modules/details/details.types";
import { canPullFocusAtom } from "@/modules/ui/can-pull-focus.atom";

import * as styles from "./details.css";
import type { EPGEntry } from "@sunrise/backend-types";
import type { ChannelId } from "@sunrise/backend-types-core";

export type DetailsButtonListProps = {
  "data-testid": string;
  epg: EPGEntry;
  channelId: ChannelId;
  time: TimeInfo;
};

const localWidgetFocusKey = {
  action: (parentFocusKey: string, idx: number) =>
    parentFocusKey + ".action." + idx,
};

export function DetailsButtonList({
  epg,
  channelId,
  time,
  "data-testid": testId,
}: DetailsButtonListProps): ReactNode {
  const t = useTranslator();
  const live = usePlayChannel({ channelId });
  const isLive = programIsPlayingAtTime(
    { startTime: time.start, endTime: time.end },
    time.now,
  );
  const preferredChildFocusKey = localWidgetFocusKey.action(
    globalFocusKey.activePage,
    0,
  );
  const { focusKey, ref, focusSelf, focused } = useFocusable({
    isFocusBoundary: true,
    focusKey: globalFocusKey.activePage,
    trackChildren: true,
    preferredChildFocusKey,
  });

  const {
    record,
    deleteRecording,
    status: recordingStatus,
  } = useRecordProgram({ epg, getCurrentFocusKey });

  const showOutOfReplayMessage = shouldShowOutOfReplayMessage(
    recordingStatus,
    time,
  );

  const {
    play: replay,
    canPlay: canReplay,
    resume,
    canResume,
  } = usePlayProgram({ epg });

  const getButtonFocusKey = useCallback(
    (index: number) => localWidgetFocusKey.action(focusKey, index),
    [focusKey],
  );

  const onRecord = (): void => {
    void record();
  };

  const canPullFocus = useAtomValue(canPullFocusAtom);
  useEffect(() => {
    if (!focused && canPullFocus) {
      focusSelf();
    }
  }, [focusSelf, canPullFocus, focused]);

  // NOTE: This recordings button has the same focusKey in all cases.
  //       It is necessary because as soon as the meaning of the button changes, the focus needs to be kept.
  const recordingsButtonFocusKey = getButtonFocusKey(1);
  let recordingsButton: JSX.Element | null = null;

  switch (recordingStatus) {
    case "recorded": {
      recordingsButton = (
        <DetailsButton
          text={t("button_recordings_delete")}
          iconLeft="remove"
          data-testid={`${testId}.delete-recording`}
          focusKey={recordingsButtonFocusKey}
          onEnterPress={deleteRecording}
        />
      );
      break;
    }
    case "planned": {
      recordingsButton = (
        <DetailsButton
          text={t("program_detail_page_button_cancel")}
          iconLeft="recording"
          iconSize={ICON_SIZE_24}
          data-testid={`${testId}.cancel-recording`}
          focusKey={recordingsButtonFocusKey}
          onEnterPress={deleteRecording}
        />
      );
      break;
    }
    default: {
      recordingsButton = (
        <DetailsButton
          text={t("program_detail_page_button_record")}
          iconLeft="record"
          iconSize={ICON_SIZE_24}
          data-testid={`${testId}.record`}
          focusKey={recordingsButtonFocusKey}
          onEnterPress={onRecord}
        />
      );
    }
  }

  return (
    <FocusContext.Provider value={focusKey}>
      {showOutOfReplayMessage ? (
        <OutOfReplayWindow />
      ) : (
        <div className={styles.actions} data-testid="details.actions" ref={ref}>
          {canReplay ? (
            <DetailsButton
              text={t("button_play")}
              iconLeft="play-circle"
              data-testid={`${testId}.play-from-beginning`}
              focusKey={getButtonFocusKey(0)}
              onEnterPress={replay}
            />
          ) : null}
          {canResume ? (
            <DetailsButton
              text={t("button_continue")}
              iconLeft="resume"
              data-testid={`${testId}.resume`}
              focusKey={getButtonFocusKey(3)}
              onEnterPress={resume}
            />
          ) : null}
          {isLive ? (
            <DetailsButton
              text={t("live")}
              data-testid={`${testId}.watch-live`}
              iconLeft="watch-live"
              iconSize={35}
              focusKey={getButtonFocusKey(4)}
              onEnterPress={() => {
                live.play();
              }}
            />
          ) : null}
          {recordingsButton}
        </div>
      )}
    </FocusContext.Provider>
  );
}
