import { assetDetailsByIdAtom } from "@sunrise/asset";
import { currentLanguageAtom } from "@sunrise/i18n";
import { nowAtom } from "@sunrise/time";
import { channelByIdAtom } from "@sunrise/yallo-channel";
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import { DetailsAtomResponse } from "./details.types";
import { epgEntryByIdAtom } from "./epg-entry-by-id.atom";
import { getDetailsForEpgAndAsset } from "./helpers/get-details-for-epg-and-asset";
import type { AssetId, EPGEntryId } from "@sunrise/backend-types-core";

type DetailsAtomArgs = {
  assetId: AssetId;
  epgId: EPGEntryId;
};

export const detailsAtom = atomFamily(({ assetId, epgId }: DetailsAtomArgs) =>
  atom<Promise<DetailsAtomResponse>>(async (get) => {
    const asset = await get(assetDetailsByIdAtom(assetId));
    const epg = await get(epgEntryByIdAtom(epgId));

    const language = get(currentLanguageAtom);
    const now = get(nowAtom);
    const channel = epg.data
      ? await get(channelByIdAtom(epg.data.channel.id))
      : null;

    return getDetailsForEpgAndAsset(
      epg.data,
      asset.data,
      channel,
      language,
      now,
    );
  }),
);
