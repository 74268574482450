import { hostsAtom, httpClientAtom } from "@sunrise/http-client";
import { selectJwtUserToken } from "@sunrise/jwt";
import { type Nullable } from "@sunrise/utils";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { isNil } from "lodash";

import { fetchUserChannelGroups } from "./channel-groups.service";
import { queryKeys, type ChannelGroup } from "@sunrise/backend-types";

export const userChannelGroupsAtom = atomWithSuspenseQuery<
  Nullable<ChannelGroup[]>,
  unknown,
  Nullable<ChannelGroup[]>,
  Nullable<ChannelGroup[]>,
  ReturnType<typeof queryKeys.channelGroups>
>((get) => {
  const host = get(hostsAtom).api;
  if (isNil(host)) throw new Error("Host is not set");

  const privateApi = get(httpClientAtom).privateApi;
  if (isNil(privateApi)) throw new Error("Private api is not set");

  return {
    queryKey: queryKeys.channelGroups(get(selectJwtUserToken)),
    queryFn: async () => {
      try {
        return await fetchUserChannelGroups(privateApi, host);
      } catch (e) {
        // TODO: handle errors
        console.error(e);
        return [];
      }
    },
    cacheTime: Infinity,
    refetchOnMount: false,
  };
});

userChannelGroupsAtom.debugLabel = "userChannelGroupsAtom";
