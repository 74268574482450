import { currentLanguageAtom, getLocalizedValue } from "@sunrise/i18n";
import { channelByIdAtom, getChannelLogo } from "@sunrise/yallo-channel";
import { recordingByRecordingIdAtom } from "@sunrise/yallo-recordings";
import { atom } from "jotai";

import { selectPlayerCurrentPlayRequest } from "./player.atom";
import { type PlayerCurrentContent } from "./types";
import type { RecordingId } from "@sunrise/backend-types-core";

const EMPTY: Readonly<PlayerCurrentContent> = {
  epgId: null,
  channelId: null,
  recordingId: null,
  title: null,
  subtitle: null,
  channelLogo: null,
  schedule: null,
};

/**
 * This assumes whatever is playing is a recording. And that for a recording, whatever is playing can't change.
 * So it just does a lookup on the recording details through the recordingId and exposes those details as the current content.
 */
export const playerCurrentContentRecordingAtom = atom<
  Promise<PlayerCurrentContent>
>(async (get) => {
  const playRequest = get(selectPlayerCurrentPlayRequest);
  const language = get(currentLanguageAtom);

  if (!playRequest || playRequest.type !== "recording") {
    return EMPTY;
  }

  const [channel, { data: recordingData }] = await Promise.all([
    get(channelByIdAtom(playRequest.channelId)),
    get(recordingByRecordingIdAtom(playRequest.recordingId)),
  ]);

  if (!recordingData || !channel || !("epg_entry" in recordingData)) {
    return EMPTY;
  }

  return {
    epgId: recordingData.epg_entry.id,
    channelId: channel.id,
    recordingId: recordingData.id as RecordingId,
    title: getLocalizedValue(recordingData.asset.title, language),
    subtitle: null,
    channelLogo: getChannelLogo(channel),
    schedule: recordingData.epg_entry
      ? {
          endTime: new Date(recordingData.epg_entry.actual_end),
          startTime: new Date(recordingData.epg_entry.actual_start),
        }
      : null,
  };
});
