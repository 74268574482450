import { TranslateFn } from "@sunrise/i18n";
import { format } from "date-fns";

import { makeRelativeDayOfWeekTranslation } from "./make-relative-day-of-week-translation";
import type { Language } from "@sunrise/backend-types-core";
import { HOUR_FORMAT } from "@sunrise/time";

export async function getRelativeTimeForEpg(
  start: Date,
  end: Date,
  now: Date,
  language: Language,
  t: TranslateFn,
): Promise<{
  status: string;
  schedule: string;
}> {
  const status = await makeRelativeDayOfWeekTranslation(
    start,
    end,
    now,
    language,
    t,
  );

  const schedule = `${format(start, HOUR_FORMAT)} – ${format(
    end,
    HOUR_FORMAT,
  )}`;

  return {
    status,
    schedule,
  };
}
