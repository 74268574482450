import { assetRatingByIdAtom, imdbTagEnabledAtom } from "@sunrise/asset";
import { Nullable } from "@sunrise/utils";
import { useAtomValue } from "jotai";
import { ReactNode, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";

import { Tag } from "@/components/tag/tag";

import * as styles from "./details-tags.css";
import { AssetId } from "@sunrise/backend-types-core";

type ImdbRatingTagProps = {
  assetId: Nullable<AssetId>;
};

function SuspendedImdbRatingTag({ assetId }: ImdbRatingTagProps): ReactNode {
  const rating = useAtomValue(assetRatingByIdAtom(assetId));
  if (!rating.data?.rating) return;

  return (
    <Tag
      className={styles.tag}
      data-testid={"imdb-rating-tag"}
      key={"imdb"}
      text={`IMDb ${rating.data.rating}`}
      uppercase={false}
    />
  );
}

export function ImdbRatingTag({ assetId }: ImdbRatingTagProps): ReactNode {
  const isImdbRatingEnabled = useAtomValue(imdbTagEnabledAtom);
  if (!assetId || !isImdbRatingEnabled) return;

  return (
    <ErrorBoundary fallback={<></>}>
      <Suspense>
        <SuspendedImdbRatingTag assetId={assetId} />
      </Suspense>
    </ErrorBoundary>
  );
}
