import { channelsOfCurrentSelectedGroupAtom } from "@sunrise/yallo-channel";
import { currentlyRequestedPlayRequestAtom } from "@sunrise/yallo-common-player-manager";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useAtomValue } from "jotai";
import { ReactNode, Suspense, useEffect, useRef } from "react";

import { ChannelItemSpinner } from "@/components";
import { globalFocusKey } from "@/config/focus-key";
import { SCREEN_HEIGHT_IN_PX, SCREEN_WIDTH_IN_PX } from "@/core";
import { FocusContainer } from "@/utils/focus-container";
import { useVirtualizerNavigation } from "@/utils/use-virtualizer-navigation";
import { localWidgetFocusKey } from "@/utils/virtualizer";

import { CHANNEL_ITEM_HEIGHT_IN_PX, ChannelItem } from "./channel-item";
import {
  channelListContainer,
  channelListItem,
  focusContainer,
  innerScroll,
} from "./channel-list.css";

type ChannelListProps = CommonProps & {
  doClose?: () => void;
  doShowMore?: () => void;
  shouldFocus?: boolean;
};

/**
 * Just renders the list of channels.
 * Should auto-select the currently playing channel or the last selected channel.
 * It will also auto-scroll to the currently playing channel.
 */
export function ChannelList({
  "data-testid": dataTestid = "channel-list",
  doClose,
  doShowMore,
  shouldFocus,
}: ChannelListProps): ReactNode {
  const playRequest = useAtomValue(currentlyRequestedPlayRequestAtom);
  const focusKey = globalFocusKey.channelList;
  const channels = useAtomValue(channelsOfCurrentSelectedGroupAtom);
  const findInitialIndex = channels.findIndex(
    (channel) => channel.id === playRequest?.channelId,
  );
  const initialIndex = findInitialIndex > -1 ? findInitialIndex : 0;

  const preferredChildFocusKey = playRequest?.channelId
    ? localWidgetFocusKey.row(focusKey, initialIndex)
    : undefined;
  const initialOffset = initialIndex * CHANNEL_ITEM_HEIGHT_IN_PX;

  const scrollRef = useRef<HTMLDivElement>(null);
  const channelsVirtualizer = useVirtualizer({
    count: channels.length,
    estimateSize: () => CHANNEL_ITEM_HEIGHT_IN_PX,
    getScrollElement: () => scrollRef.current,
    overscan: 2,
    initialOffset,
    initialRect: {
      width: SCREEN_WIDTH_IN_PX,
      height: SCREEN_HEIGHT_IN_PX, // size of scroll element
    },
  });

  useEffect(() => {
    channelsVirtualizer.scrollToIndex(initialIndex, {
      align: "center",
    });
  }, [channelsVirtualizer, initialIndex]);

  const { handleGoToRow } = useVirtualizerNavigation({
    virtualizer: channelsVirtualizer,
    focusKey,
    align: "center",
  });
  const virtualItems = channelsVirtualizer.getVirtualItems();

  return (
    <FocusContainer
      focusKey={focusKey}
      onLeft={doClose}
      onRight={doShowMore}
      shouldFocus={shouldFocus}
      className={focusContainer}
      boundary
      preferredChildFocusKey={preferredChildFocusKey}
    >
      {(handlers) => (
        <div
          data-testid={dataTestid}
          ref={scrollRef}
          className={channelListContainer}
        >
          <div
            className={innerScroll}
            style={{
              position: "relative",
              height: `${channelsVirtualizer.getTotalSize()}px`,
            }}
          >
            {virtualItems.map((virtualItem) => {
              const index = virtualItem.index;
              const channel = channels[index];
              if (!channel) return null;

              const childFocusKey = localWidgetFocusKey.row(focusKey, index);

              const itemStyle: React.CSSProperties = {
                position: "absolute",
                top: 0,
                left: 0,
                transform: `translateY(${virtualItem.start}px)`,
              };

              return (
                <Suspense
                  key={virtualItem.index}
                  fallback={
                    <ChannelItemSpinner
                      focusKey={childFocusKey}
                      className={channelListItem}
                      data-testid={dataTestid}
                      style={itemStyle}
                    />
                  }
                >
                  <ChannelItem
                    onScreen={!!virtualItem}
                    item={channel}
                    focusKey={childFocusKey}
                    onLeft={handlers.onLeftBound}
                    onRight={handlers.onRightBound}
                    onDown={handleGoToRow(index, channels.length - 1, 1)}
                    onUp={handleGoToRow(index, 0, -1)}
                    data-testid={`${dataTestid}.list-el.${channel.id}`}
                    style={itemStyle}
                  />
                </Suspense>
              );
            })}
          </div>
        </div>
      )}
    </FocusContainer>
  );
}
