import { atom } from "jotai";

import { channelGroupsAtom } from "./channel-groups.atom";
import "./channel-groups.service";
import { userChannelGroupsAtom } from "./user-channel-groups.atom";
import type { ChannelGroup } from "@sunrise/backend-types";

/**
 * These are all the channel groups how the user sees them in the UI.
 * The user's channel groups before the static channel groups.
 */
export const selectableChannelGroupsAtom = atom<Promise<ChannelGroup[]>>(
  async (get) => {
    const [channelGroups, userChannelGroups] = await Promise.all([
      get(channelGroupsAtom),
      get(userChannelGroupsAtom),
    ]);

    // User channel groups take priority over static channel groups
    return [...(userChannelGroups.data ?? []), ...(channelGroups.data ?? [])];
  },
);
