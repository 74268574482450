import { currentLanguageAtom, getLocalizedValue } from "@sunrise/i18n";
import { type Nullable } from "@sunrise/utils";
import { atom } from "jotai";

import { selectedChannelGroupAtom } from "./selected-channel-groups.atom";
import type { ChannelGroupId } from "@sunrise/backend-types-core";

export const currentChannelGroupForDisplayingAtom = atom<
  Promise<
    Nullable<{
      name: string;
      id: ChannelGroupId;
    }>
  >
>(async (get) => {
  const selected = await get(selectedChannelGroupAtom);
  const language = get(currentLanguageAtom);

  if (!selected) {
    return null;
  }

  return {
    name: getLocalizedValue(selected.display_name, language) ?? "",
    id: selected.id,
  };
});
