import { useTranslator } from "@sunrise/translator";
import { isWithinReplayWindow } from "@sunrise/yallo-replay";
import { ReactNode } from "react";

import { InfoBox } from "@/components/boxes/info-box/info-box";
import { TimeInfo } from "@/modules/details/details.types";
import type { RecordingState } from "@sunrise/backend-types";

export function shouldShowOutOfReplayMessage(
  recordingStatus: RecordingState | undefined,
  time: TimeInfo,
): boolean {
  const inFuture = time.now <= time.start;

  return (
    !inFuture &&
    recordingStatus !== "recorded" &&
    !isWithinReplayWindow(time.start, time.now).isInReplayWindow
  );
}

export function OutOfReplayWindow(): ReactNode {
  const t = useTranslator();

  return <InfoBox>{t("detail_page_out_of_replay_window_text")}</InfoBox>;
}
