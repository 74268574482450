import { getLocalizedValue } from "@sunrise/i18n";
import { isDefined } from "@sunrise/utils";

import { getSeasonEpisodePrefix } from "@/modules/details/get-season-episode-prefix";
import type {
  SeriesEpisodeAssetForEpg,
  SingleAssetForEpg,
} from "@sunrise/backend-types";
import type { Language, Localized } from "@sunrise/backend-types-core";

export function getPrefixAndSubtitle(
  asset: SingleAssetForEpg | SeriesEpisodeAssetForEpg,
  subtitle: Localized<string>,
  originalSubtitle: string | undefined,
  language: Language,
): string {
  const prefix =
    asset.type === "seriesepisode"
      ? getSeasonEpisodePrefix(asset.raw_season, asset.episode)
      : null;

  const text = getLocalizedValue(subtitle, language) ?? originalSubtitle;
  return [prefix, text].filter(isDefined).join(": ");
}
